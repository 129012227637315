import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from '../../../../../utils/useLocalStorage';
import type { IUser } from '../../../../../../app/auth/auth.interfaces';
import { chatSessionIdLocalStorageKey, userPhoneLength } from '../../../../../../app/constants';
import { useFormContext } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../../../../app/store';
import type { IUpdatePreferredNameReqPayload } from '../../SettingsMenu.interfaces';
import {
  updatePreferredNameReq,
  updateUserProfileReq,
} from '../../../../../../app/useAppData/user.store';
import { useApiData } from '../../../../../hooks/useApiData';
import { isErrorCodeNotForbiddenOrUnauthorized } from '../../../../../utils/isErrorCodeNotForbiddenOrUnauthorized';
import { EditItemSection } from './EditItemSection';
import type { IProfileForm } from '../../SettingsMenu';
import { userPhoneFormatter } from '../../SettingsMenu.utils';
import createAppOverlayPopover, {
  EAppOverlaySlideInMobileAnimation,
} from '../../../../app-overlay-popover/createAppOverlayPopover';
import EditPhoneModal from './edit-phone-modal/EditPhoneModal';
import { ECloseSwipeDirection } from '../../../../../hooks/swipe-hooks/swipe.utils';

export const EditProfileForm = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { getUserProfile, updateUserProfile, updatePreferredName, user } = useAppSelector(
    (store) => store.userReducer,
  );
  const { sessionResponse } = useAppSelector((store) => store.chatReducer);
  const [sessionIdSessionStorage] = useLocalStorage(chatSessionIdLocalStorageKey, '');
  const focusTimerRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    return () => {
      if (focusTimerRef.current) clearTimeout(focusTimerRef.current);
    };
  }, []);

  const form = useFormContext<IProfileForm>();

  const watchIsNameEditable = form.watch('editName');
  const watchShouldDisplayPhoneError = form.watch('shouldDisplayPhoneError');
  const watchShouldDisplayNameError = form.watch('shouldDisplayNameError');

  // Update the form and the localStorage with the latest data retrieved from the server using the getUserProfile request
  useApiData(getUserProfile, {
    onFulfilled(data) {
      updateFormDataFromServer(data);
    },
  });

  const onUpdatePhone = () => {
    const reqPayload = { phoneNumber: form.getValues().phoneNumber.replaceAll(' ', '') };
    dispatch(updateUserProfileReq(reqPayload));
  };

  const onUpdatePreferredName = () => {
    const updateNameReqPayload: IUpdatePreferredNameReqPayload = {
      preferredName: form.getValues().preferredName,
      sessionId: sessionResponse?.data?.sessionId || sessionIdSessionStorage,
    };
    dispatch(updatePreferredNameReq(updateNameReqPayload));
  };

  const apiToFormField: { [key: string]: () => void } = {
    phoneNumber: onUpdatePhone,
    preferredName: onUpdatePreferredName,
  };

  const onSubmitForm = () => {
    const fields = form.formState.dirtyFields;
    Object.keys(fields).map((k) => apiToFormField[k]());
    form.setValue('editName', false);
    form.setValue('editPhone', false);
    form.reset({}, { keepValues: true });
  };

  const mapForEditItem: { [key: string]: keyof IProfileForm } = {
    phoneNumber: 'editPhone',
    preferredName: 'editName',
  };

  const onFocusInput = (name: keyof IProfileForm) => {
    if (watchShouldDisplayPhoneError) form.setValue('shouldDisplayPhoneError', false);
    if (watchShouldDisplayNameError) form.setValue('shouldDisplayNameError', false);
    form.setValue(mapForEditItem[name], true);
    // for awaiting the input displayed on the screen
    if (focusTimerRef.current) clearTimeout(focusTimerRef.current);
    focusTimerRef.current = setTimeout(() => {
      form.setFocus(name);
    }, 0);
  };

  // on change phone input - convert the input value to 'xxx xxx xxxx' format as the input changes.
  const handlePhoneNumberFormatChange = (event: any) => {
    const inputValue = event.target.value;
    form.setValue('phoneNumber', (inputValue as string).replaceAll(' ', ''));
  };

  useApiData(updateUserProfile, {
    onFulfilled(data) {
      updateFormDataFromServer(data);
    },
    // If update profile rejected -> revert to the previous values and display an error
    onRejected(error) {
      if (isErrorCodeNotForbiddenOrUnauthorized(error.code)) {
        // if rejected and the phone was change revert to the prev phone value and display a phone error
        if (form.getValues('phoneNumber') !== userPhoneFormatter(user?.phoneNumber || '')) {
          form.reset((formValues) => ({
            ...formValues,
            phoneNumber: userPhoneFormatter(user?.phoneNumber || ''),
            shouldDisplayPhoneError: true,
          }));
        }
        // if rejected and the notification was change revert to the prev notification value and display a notification error
        else if (
          form.getValues().userTextNotificationsEnabled !== user?.userTextNotificationsEnabled
        ) {
          form.reset((formValues) => ({
            ...formValues,
            userTextNotificationsEnabled: user?.userTextNotificationsEnabled || false,
            shouldDisplayNotificationError: true,
          }));
        }
      }
    },
  });

  useApiData(updatePreferredName, {
    onFulfilled(data) {
      updateFormDataFromServer(data);
    },
    // If update profile rejected -> revert to the previous values and display an error
    onRejected(error) {
      if (isErrorCodeNotForbiddenOrUnauthorized(error.code)) {
        form.reset((formValues) => ({
          ...formValues,
          preferredName: user?.preferredName || '',
          shouldDisplayNameError: true,
        }));
      }
    },
  });

  const updateFormDataFromServer = (data: IUser) => {
    form.reset((formData) => ({
      ...formData,
      isUsPhoneNumber: !!data?.phoneNumber?.startsWith('+1'),
      phoneNumber: userPhoneFormatter(data?.phoneNumber ?? ''),
      userTextNotificationsEnabled: !!data?.userTextNotificationsEnabled,
      preferredName: data?.preferredName ?? '',
    }));
  };

  const onOpenPhoneModal = () => {
    createAppOverlayPopover(
      <EditPhoneModal
        phoneNumber={form.getValues().phoneNumber}
        setPhoneNumber={(phone: string) => form.setValue('phoneNumber', phone)}
      />,
      'edit-phone-overlay ',
      null,
      null,
      {
        // overlay config
        slideInMobileAnimation: EAppOverlaySlideInMobileAnimation.FULL_SCREEN,
        shouldCloseBySwipeOnMobile: true,
        closeSwipeDirection: ECloseSwipeDirection.DOWN,
      },
    );
  };

  return (
    <form onSubmit={form.handleSubmit(onSubmitForm)}>
      <EditItemSection
        register={form.register(`preferredName`, { required: true, onBlur: onSubmitForm })}
        desktopLabel={t('settingsPersonalInfoNameLabel')}
        mobileLabel={t('settingsPersonalInfoNameLabel')}
        subLabel={t('settingsPersonalInfoNameSubLabel')}
        inputValue={form.getValues().preferredName}
        isInputEditable={watchIsNameEditable}
        onFocusInput={() => onFocusInput('preferredName')}
        shouldDisplayError={watchShouldDisplayNameError}
        inputName="preferredName"
        editTestId="edit-name"
        inputType="text"
      />
      <EditItemSection
        register={form.register(`phoneNumber`, {
          required: true,
          minLength: userPhoneLength,
          maxLength: userPhoneLength,
          onBlur: onSubmitForm,
          onChange: handlePhoneNumberFormatChange,
        })}
        desktopLabel={t('settingsPersonalInfoPhoneLabel')}
        mobileLabel={t('settingsPersonalInfoPhoneLabel')}
        subLabel={t('settingsPersonalInfoPhoneSubLabel')}
        inputValue={form.getValues().phoneNumber}
        isInputEditable={false}
        onFocusInput={() => onOpenPhoneModal()}
        shouldDisplayError={watchShouldDisplayPhoneError}
        inputName="phoneNumber"
        editTestId="edit-phone"
        className="edit-phone"
        maxLengthInput={userPhoneLength}
        inputType="tel"
      />
    </form>
  );
};
