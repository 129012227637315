import { useFormContext } from 'react-hook-form';
import type { IProfileForm } from '../SettingsMenu';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../../app/store';
import { updateUserProfileReq } from '../../../../../app/useAppData/user.store';
import { Switch } from 'antd';
import { ErrorMessage } from '../error-message/ErrorMessage';

export const Notifications = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const form = useFormContext<IProfileForm>();

  const watchShouldDisplayNotificationError = form.watch('shouldDisplayNotificationError');
  const watchUserTextNotificationsEnabled = form.watch('userTextNotificationsEnabled');

  const onUpdateNotificationEnabled = () => {
    const reqPayload = {
      userTextNotificationsEnabled: form.getValues().userTextNotificationsEnabled,
    };
    dispatch(updateUserProfileReq(reqPayload))
      .unwrap()
      .then()
      .catch(() => {
        form.setValue('shouldDisplayNotificationError', true);
        form.setValue('userTextNotificationsEnabled', watchUserTextNotificationsEnabled);
      });
  };

  const onChangeNotificationSwitch = (checked: boolean) => {
    if (watchShouldDisplayNotificationError) form.setValue('shouldDisplayNotificationError', false);
    form.setValue('userTextNotificationsEnabled', checked);
    onUpdateNotificationEnabled();
  };

  return (
    <section className="notification">
      <div>
        <h3 className="title static-string mobile-label-text">
          {t('settingsPersonalInfoNotificationTitleMobile')}
        </h3>
        <h3 className="title static-string desktop-label-text">
          {t('settingsPersonalInfoNotificationTitleDesktop')}
        </h3>
        <p>{t('settingsPersonalInfoNotificationSubTitle')}</p>
      </div>
      <Switch
        onChange={(checked) => onChangeNotificationSwitch(checked)}
        checked={form.getValues().userTextNotificationsEnabled}
        data-testid="notification-switch"
        className="app-toggle app-toggle-active-dark"
      />
      {watchShouldDisplayNotificationError && <ErrorMessage />}
    </section>
  );
};
