import type { ELoginPageViewType } from './login/Login';

export interface ILoginResponse {
  token: string;
  refreshToken: string;
  shouldLink: boolean;
}

export interface IUser {
  id: string;
  email: string | null;
  name: string | null;
  profileImage: string | null;
  phoneNumber?: string | null;
  userTextNotificationsEnabled: boolean;
  carrierTextNotificationsEnabled: boolean;
  preferredName: string | null;
  isInternalUser?: boolean;
  shouldDisplayFeedbackSurvey?: boolean;
  calendarProvider: string | null;
  proactiveReminders: IProactiveReminders;
  isActive?: boolean;
  canRegister?: boolean;
  clientFlags?: number;
  featureFlags?: IUserFeatureFlags;
  signUpType?: string;
}

export interface IVerifyOtpResponse {
  user: IUser;
  shouldLink: boolean;
}

export interface IUserFeatureFlags {
  [key: string]: boolean;
}

export interface IProactiveReminders {
  proactiveDailyReminderHour: number | null; // 0-23
  proactiveEveningReminderHour: number | null; // 0-23
  isProactiveWorkBlockCheckInReminderEnabled: boolean;
  isProactiveEndOfWorkTimeReminderEnabled: boolean;
}

export enum ELoginType {
  GOOGLE = 'google',
  APPLE = 'apple',
  DISCORD = 'discord',
  EMAIL = 'email',
  PHONE = 'phone',
}

export interface ILoginReqPayload {
  accessToken: string;
  redirectUri: string;
  error: string;
  phoneNumber?: string | null;
  email?: string | null;
}

export interface IRegisterPayload {
  registrationParameters?: string | null;
}
export class AddPhoneRegistrationForm {
  userPhone = '';
}
export interface IRegisterPhoneNumberRequestPayload {
  phoneNumber?: string;
  phoneCountryTwoLetterCode?: string;
}

export interface ICalendarProvider {
  provider: string;
}

export enum ECalendarSyncType {
  FULL = 1,
  EXPORT,
  IMPORT,
}

export enum EExternalCalendarProvider {
  GOOGLE = 'google',
  OUTLOOK = 'outlook',
  ICAL = 'ical',
}

export interface IVerifyOtpPayload {
  phoneNumber?: string;
  otp?: string;
  phoneCountryTwoLetterCode?: string;
  context: 'CreateUser' | 'UpdateSettings';
}

export interface LoginPageProps {
  viewType: ELoginPageViewType;
}

export interface IMagicLinkReqPayload {
  phoneNumber?: string;
  phoneCountryTwoLetterCode?: string;
  email?: string;
  redirectUri: string;
  registrationParameters?: string;
  isShortCodeRequested: boolean;
}

export enum EOperatingSystem {
  IOS = 'ios',
  ANDROID = 'android',
  UNKNOWN = 'unknown',
}
