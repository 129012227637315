export const tokenLocalStorageKey = 'maximalLearningToken';
export const refreshTokenLocalStorageKey = 'maximalLearningRefreshToken';
export const loginTypeStorageKey = 'maximalLearningLoginType';
export const operatingSystemLocalStorageKey = 'maximalLearningOperatingSystem';
export const selectedOptionPerMsgIdKey = 'selectedOptionPerMsgId';
export const chatSessionIdLocalStorageKey = 'sessionId';
export const redirectUrlStorageKey = 'redirectUrl';
export const sessionIdRegex =
  /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
export const maximalLearningDomain = 'https://www.maximallearning.com';
export const privacyPolicyLink = `${maximalLearningDomain}/privacy`;
export const termsOfServiceLink = `${maximalLearningDomain}/tos`;
export const contactUsLink = `${maximalLearningDomain}/contact`;
export const discordLink = `https://discord.com/invite/kS4Q9Wq393`;
export const discordLinkForOpenUsers = `https://discord.gg/HVbuMSSyZD`;
export const messageTestId = 'message';
export const dislikeFeedbackParam = 'dislikeFeedback';
export const settingsMenuParam = 'settingsMenu';
export const chatHistoryParam = 'chatHistory';
export const userPhoneLength = 20;
export const numberOfMessagesToFetch = 100;
export const inviteFriendsLink = (userId: string) =>
  `https://join.maximallearning.com/?utm_medium=inapp&utm_source=referral&utm_term=` + userId;
export const messagesAmountToDisplayFeedbackModal = 20;
export const stageParam = 'stageContent';
export const colorPalette = [
  'transparent',
  '#E9BB16',
  '#EF8757',
  '#C490D1',
  '#277DFE',
  '#1B998B',
  '#F857DE',
  '#43C1D2',
];
export const sessionSourceTypeStorageKey = 'sessionSourceTypeId';
export const surveyPrepopulatedOptionText =
  'Can I ask you to fill out a quick survey to give us feedback on this latest version?';
export const taskDueDateDefaultTime = '11:59PM';
export const animationDurationTaskFormInMs = 800;
export const recentTaskTagsLocalStorageKey = 'maximalLearningRecentTaskTags';
export const taskFormParam = 'taskForm';
export const bodyInputFocusedClassName = 'input-focused';
export const chatInputId = 'student-input';
export const isChatInputFocusedLocalStorageKey = 'studentInputFocused';
export const ageGateLocalStorageKey = 'ageGatePass';
export const registrationParametersStorageKey = 'registrationParameters';
export const slideUpFullScreenMobilePopoverClassName = 'slideIn-full-screen-mobile';
export const slideUpHalfScreenMobilePopoverClassName = 'slideIn-half-screen-mobile';
export const subTaskOverlayPopoverClassName =
  'sub-task-overlay scroll-y-container-hidden-scroll-bar';
export const userPhoneNumberLocalStorageKey = 'userPhoneNumber';
export const userPhoneCountryTwoLetterCodeLocalStorageKey = 'userPhoneCountryTwoLetterCode';
export const workBlockDetailsParam = 'workBlockDetails';
export const workBlockDetailsFormParam = 'workBlockDetailsForm';
export const timePickerContextOverlayDefaultWidth = 350;
export const selectedPlanViewTypeLocalStorageKey = 'selectedPlanViewType';
export const plannerModeOnLocalStorageKey = 'plannerModeOn';
export const isSignUpLocalStorageKey = 'isSignUp';
export const isLoginFromMagicLinkLocalStorageKey = 'isLoginFromMagicLink';
export const productTourDataAttrChat = 'chat-nav';
export const productTourDataAttrScratchpad = 'scratchpad-nav';
export const productTourDataAttrTasks = 'tasks-nav';
export const productTourDataAttrPlan = 'plan-nav';
export const productTourDataAttrUserAvatarMobile = 'user-avatar-mobile';
export const productTourDataAttrUserAvatarDesk = 'user-avatar-desk';
export const routeParamsLocalStorageKey = 'routeParams';
export const popUpToastOverlayClassName = 'app-toast-overlay-container';
export const shouldShowDiscordToastInCurrentSessionKey = 'shouldDisplayDiscordToastCurrentSession';
export const discordToastId = 'discord-toast';
export const turnsCounterStorageKey = 'maximalLearningTurnsCounter';
export const turnsAmountToDisplayDiscordToast = 10;
export const calendarHourLineElementClassName = 'calendar-hour-line';
export const calendarHourLineTransparentElClassName =
  calendarHourLineElementClassName + '--transparent';
export const lastSurveyOfferedDayOfWeekKey = 'lastSurveyOfferedDayOfWeek'; // 0-6, 0 is Sunday
export const plannerTaskListContainerClassName =
  'stage-planner-content-unscheduled-tasks-container';
export const chatRecordCookieName = 'speech-token';
export const planTourDataAttrChangeViewsDesk = 'change-views-menu-desk';
export const planTourDataAttrChangeViewsMobile = 'change-views-menu-mobile';
export const planTourDataAttrNavigateDesk = 'plan-navigate-desk';
export const planTourDataAttrNavigateMobile = 'plan-navigate-mobile';
export const planTourDataAttrPlannerDesk = 'planner-desk';
export const planTourDataAttrPlannerMobile = 'planner-mobile';

export const APP_ROUTING_PATHS = {
  PAGE_404: 'page-404',
  HOME_PAGE: 'home-page',
  HOME: '/app',
  AUTHLAYOUT: '/auth',
  LOGOUT: '/auth/logout',
  SSO: '/auth/sso',
  SSO_MAGIC_LINK: '/auth/sso/link',
  SSO_FAILED: '/auth/sso-error',
  ADD_PHONE_REGISTER: '/auth/add-phone',
  CHAT: 'chat',
  REDIRECT: '/redirect',
  SESSION_SOURCE_TYPE: '/n/:sessionSourceTypeId',
  WAIT_LIST: '/auth/wait-list',
  PHONE_VERIFICATION: '/auth/phone-verification',
  SIGN_IN: '/auth/signin',
  SIGN_UP: '/auth/signup',
  MAGIC_LINK_PHONE: '/auth/link/phone',
  MORE_INFO: '/auth/more-info',
  MORE_INFO_LAST_SCREEN: '/auth/more-info/last-screen',
  MAGIC_LINK_EMAIL: '/auth/link/email',
  MOBILE_APP_CHECK_LOGIN_APPLE: '/auth/sso/mobile/apple',
  MOBILE_APP_CHECK_LOGIN_GOOGLE: '/auth/sso/mobile/google',
  MOBILE_APP_CHECK_LOGIN_EMAIL: '/auth/sso/mobile/email',
  MOBILE_APP_CHECK_LOGIN_PHONE: '/auth/sso/mobile/phone',
  MOBILE_APP_CHECK_LOGIN_ERROR: '/auth/sso/mobile/error',
  LINK_ACCOUNTS: '/auth/link-accounts',
  COMPLETE_LINK: '/auth/complete-link',
};

export const API_ROUTES = {
  CHAT: {
    CREATE_OR_CONTINUE_SESSION: '/CreateOrContinueSession',
    HUMAN_STUDENT_TURN: '/HumanStudentTurn',
    FEEDBACK: '/MessageFeedback ',
    HISTORY: '/History',
    SESSION_SUMMARIES: '/SessionSummaries',
    DOWNLOAD_SESSION: '/DownloadSessionAsDocx',
  },
  AUTH: {
    REFRESHTOKEN: '/auth/refresh-token/',
    LOGIN: 'auth/login/',
    LOGOUT: '/auth/logout',
    GOOGLE_CALENDAR_LOGIN: 'auth/login/google-calendar',
    OUTLOOK_CALENDAR_LOGIN: 'auth/login/outlook-calendar',
    ICAL_CALENDAR_LOGIN: 'auth/login/ical-calendar',
    REGISTER: 'auth/register',
    REDIRECT: 'auth/redirect',
    SEND_OTP: 'send-otp',
    VERIFY_OTP: 'verify-otp/v2',
    REQUEST_MAGIC_LINK_PHONE: 'auth/link/phone',
    REQUEST_MAGIC_LINK_EMAIL: 'auth/link/email',
  },
  SETTINGS: {
    USER_PROFILE: '/userProfile',
    RESET_USER_PROFILE: '/reset',
    USER_FEEDBACK: '/userFeedback',
    PREFERRED_NAME: '/preferredName',
    REMOVE_CALENDAR_SYNC: '/removeCalendarSync',
  },
  USER: {
    UPDATE_USER_INFO: 'user/update/info',
    USER_APP_DATA: '/userAppData',
    LINK_ACCOUNTS: 'user/link-accounts/',
  },
  SPEECH: {
    GET_SPEECH_SERVICE_TOKEN: 'Speech/GenerateToken',
  },
  TASKS: {
    TASKS_LIST: '/tasks/list',
    UPDATE_TASK: '/tasks/update',
    CREATE_TASK: '/tasks/create',
    CLEAR_SCRATCHPAD: '/tasks/clear-scratchpad',
  },
  WORK_BLOCK: {
    CREATE_WORK_BLOCK: '/workblocks/create',
    UPDATE_WORK_BLOCK: '/workblocks/update',
  },
};

export const aiToolNames = {
  UX_SET_TASK_FIELD: '[UX_SET_TASK_FIELD]',
  UX_ENTER_FORM: '[UX_ENTER_FORM]',
  UX_ADD_TASK: '[UX_ADD_TASK]',
  XU_ENTER_FORM: '[XU_ENTER_FORM]',
  LIST_TASKS: '[LIST_TASKS]',
  UPDATE_TASK: '[END_UPDATE_TASK]',
  DELETE_TASK: '[END_REMOVE_TASK]',
  SCHEDULE_MANAGER: '[SCHEDULE_MANAGER]',
};

export enum EUserAgeSlice {
  MINOR = 1,
  TEENAGER,
  ADULT,
}

export const dataAttributes = {
  processingMessageType: 'data-processing-message-type',
  chatToastMessageDataType: 'data-chat-toast-message-data-type',
};

export const sendUserActivationConversionEvent = (data: any = {}) => {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: 'webapp_user_activation',
      ...data,
    });
  }
};

export const fullDayDurationInSeconds = 86400;

export enum EMagiLinkLoginPageViewType {
  REQUEST_MAGIC_LINK = 1,
  MAGIC_LINK_SENT,
  MAGIC_LINK_SENT_DESKTOP,
}
